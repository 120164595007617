.Notify {
    position: fixed;
    top: 3vh;
    left: 35vw;
    width: 30vw;
    height: 5vh;
    border: 1px solid gainsboro;
    border-radius: 5px;
    z-index: 9999;
    background: white;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
}
