.scrollView {
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollView::-webkit-scrollbar {
    width: 10px;
    background-color: hsl(0, 0%, 22%)
}

.FormContainer.CreateVideo {
    background-color: #1f1e1e;
    border-radius: 10px;
    padding: 20px;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    box-shadow: 12px 12px 2px 1px rgba(99, 99, 99, 0.2);
}

.FormContainer  h1 {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: #5e5c5c;
}

.FormContainer label {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #5e5c5c;
}

.FormContainer  select {
    width: 100%;
    height: 40px;
    border: 1px solid hsl(0, 0%, 22%);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: hsl(0, 0%, 22%);
}

.FormContainer input {
    border: 1px solid hsl(0, 0%, 22%);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: hsl(0, 0%, 22%);
}

@media only screen and (max-width: 845px) {
    .FormContainer.CreateVideo {
        width: 90%;
    }
}
