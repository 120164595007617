.register-button {
    background-color: #FF3636;
    border: 1px solid #FF3636;
    border-radius: 10px;
    padding: 10px;
    margin-top: 15px;
    width: 100%;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.register-button:hover {
    background-color: #fc2323;
    border: 1px solid #FF3636;
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
    width: 100%;
    color: white;
}
