.notice {
    border: 2px solid gainsboro;
    background: black;
    color: white;
    border-radius: 10px;
    padding: 20px;
    width: 25%;
    top: 45%;
    left: 35%;
    position: fixed;
    z-index: 1;
}

.notice-close {
    position: absolute;
    left: 90%;
    top: 10px;
    font-size: 17px;
    color: red;
    background: white;
    border-radius: 5px;
}
