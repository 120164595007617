.Home {
    color: hsl(0, 0%, 22%);
}

.Home .EditProfilePicture {
    width: 80vw;
    height: 50vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3vh;
    border: 1px solid hsl(0, 0%, 22%);
    border-radius: 10px;
    padding: 10px;
}

.Home .EditProfilePicture .EditProfilePictureButton {
    float: right;
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    background: #f75252;
    color: white;
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 15px;
}

.Home .EditProfilePicture .EditProfilePictureButton:hover {
    background: red;
}

.Home .EditProfile {
    width: 80vw;
    margin-top: 3vh;
    margin-left: auto;  
    margin-right: auto;
    border: 2px solid hsl(0, 0%, 22%);
    border-radius: 10px;
    padding: 10px;
}

.Home .EditProfile .EditProfileTitle {
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 35px;
    font-size: bold;
}

.Home .EditProfile .EditProfileInputJoin {
    display: grid;
    grid-template-columns: 50% 50%;
}


.Home .EditProfileInput {
    width: 100%;
    display: grid;
}

.Home .EditProfileInputTitle {
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 23px;
    font-size: bold;
    margin-bottom: 10px;
}

.Home .EditProfileInputField, .Home .EditProfileInputBio {
    width: 80%;
    border: 2px solid hsl(0, 0%, 22%);
    background: hsl(0, 0%, 15%);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 2%;
}

.Home .EditProfileInputBio {
    width: 97%;
    height: 10vh;
    overflow: auto;
    background: hsl(0, 0%, 15%);
    color:hsl(0, 0%, 65%);
}

.Home .EditProfileInputField:active, .Home .EditProfileInputBio:active {
    border: 2px solid black;
}

.Home .EditProfileButtons {
    position: relative;
    text-align: right;
    bottom: 5vh;
}

.Home .EditProfileButton {
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    background: #f75252;
    color: white;
    font-family: "IM FELL Great Primer SC", serif;
    font-size: 15px;
    margin-right: 10px;
}
